<template>

    <div>
        <h1>Favorite</h1>
    </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>


<style scoped>
</style>